<template>
  <div class="profile" :class="{'skeleton': !state.maker.loaded}">
    <div class="panel">
      <div class="top">
        <Anchor :href="`/channel/${builderSeq}?tabs=projects`">
          <MemberPicture :memberSeq="builderSeq" size="40" :skeleton="!state.maker.loaded"/>
        </Anchor>
        <div class="right">
          <Anchor class="ellipsis pointer font-sm no-underline" :href="`/channel/${builderSeq}?tabs=projects`" :title="state.maker.info.memberName">{{ state.maker.info.memberName }}</Anchor>
          <span class="badge badge-point-soft font-xs" v-if="projectType !== 'invest'">진행한 프로젝트 {{ state.maker.info.projects }}</span>
        </div>
      </div>
      <div class="intro">
        <div class="content" :class="{expand: state.intro.expand}" :id="`${component.name}Intro`">
          <span>{{ state.maker.info.introduction ? state.maker.info.introduction.trim() : "등록된 소개 내용이 없습니다." }}</span>
        </div>
        <span class="d-block color-point font-xs pt-1 pointer" v-if="state.intro.overflow" @click="toggleIntro()">{{ state.intro.expand ? "간략히" : "전체 보기" }}</span>
      </div>
      <div class="status">
        <router-link :to="`/channel/${builderSeq}?tabs=followers`" class="stat no-underline">
          <span class="font-sm color-secondary pr-2">팔로워</span>
          <b class="font-sm">{{ $lib.getNumberFormat(state.maker.info.followers) }}</b>
        </router-link>
        <template v-if="projectType !== 'invest'">
          <router-link :to="`/channel/${builderSeq}?tabs=cheers`" class="stat no-underline">
            <span class="font-sm color-secondary pr-2">응원</span>
            <b class="font-sm">{{ $lib.getNumberFormat(state.maker.info.cheers) }}</b>
          </router-link>
          <router-link :to="`/channel/${builderSeq}?tabs=reviews`" class="stat no-underline">
            <span class="font-sm color-secondary pr-2">후기</span>
            <b class="font-sm">{{ $lib.getNumberFormat(state.maker.info.reviews) }}</b>
          </router-link>
        </template>
        <router-link :to="`/channel/${builderSeq}?tabs=projects`" class="stat no-underline" v-else>
          <span class="font-sm color-secondary pr-2">진행 이력</span>
          <b class="font-sm">{{ $lib.getNumberFormat(state.maker.info.projects) }}</b>
        </router-link>
      </div>
      <div class="actions">
        <template v-if="builderSeq !== $store.state.account.memberSeq">
          <button class="font-xs btn" :class="builderSeq && $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq) ? 'btn-light' : 'btn-point'"
                  :disabled="!$store.state.follows.loaded || !state.maker.loaded"
                  @click="toggleFollow(builderSeq)">
                      <span :class="{'text-muted': $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq)}">
                        {{ $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq) ? "팔로잉" : "+ 팔로우" }}
                      </span>
          </button>
          <button class="font-xs btn btn-bordered-point message" @click="openMessageModal()">문의하기</button>
        </template>
        <Anchor :href="`/mypage/account/channel`" class="font-xs btn btn-default edit" v-else>채널 관리하기</Anchor>
      </div>
    </div>
    <div class="accordion" v-if="computedIsAccordionShow">
      <div :id="`${component.name}Contacts`" class="child collapse">
        <div>
          <ReviewTagBar
              v-if="projectType === 'reward' && builderSeq"
              classType="maker"
              :skeleton="!state.maker.loaded"
              :builderSeq="builderSeq"
              :url="`/api/project/maker/${builderSeq}/review/tags`"
          />
          <div class="contacts" v-if="state.maker.info.homepage || state.maker.info.email">
            <a :href="getLink(state.maker.info.homepage)" target="_blank" rel="noopener noreferrer" class="no-underline" title="홈페이지" v-if="state.maker.info.homepage">
              <span class="img" style="background-image: url(/assets/ico/component.maker-info.home.svg)"></span>
              <span class="color-secondary font-sm address">{{ getLink(state.maker.info.homepage) }}</span>
            </a>
            <template v-if="state.maker.info.email">
              <a class="no-underline pointer" title="메일 주소 복사" @click="copyEmailAddress(state.maker.info.email)" v-if="$env.device === 'desktop'">
                <span class="img" style="background-image: url(/assets/ico/component.maker-info.mail.svg)"></span>
                <span class="color-secondary font-sm address">{{ state.maker.info.email }}</span>
              </a>
              <a class="no-underline pointer" :href="`mailto:${state.maker.info.email}`" v-else>
                <span class="img" style="background-image: url(/assets/ico/component.maker-info.mail.svg)"></span>
                <span class="color-secondary font-sm address">{{ state.maker.info.email }}</span>
              </a>
            </template>
          </div>
          <div class="sns" v-if="state.maker.info.facebook||state.maker.info.blog||state.maker.info.insta">
            <b class="font-sm">SNS</b>
            <div class="actions">
              <a class="img" :href="getLink(state.maker.info.facebook)" target="_blank" rel="noopener noreferrer" style="background-image: url(/assets/ico/component.maker-info.facebook.svg)"
                 title="페이스북" v-if="state.maker.info.facebook"
              ></a>
              <a class="img" :href="getLink(state.maker.info.blog)" target="_blank" rel="noopener noreferrer" style="background-image: url(/assets/ico/component.maker-info.blog.svg)"
                 title="블로그" v-if="state.maker.info.blog"
              ></a>
              <a class="img" :href="getLink(state.maker.info.insta)" target="_blank" rel="noopener noreferrer" style="background-image: url(/assets/ico/component.maker-info.insta.svg)"
                 title="인스타그램" v-if="state.maker.info.insta"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="parent">
        <div class="pointer collapsed" data-toggle="collapse" :data-target="`#${component.name}Contacts`">
          <span class="font-sm"></span>
          <i class="fa fa-angle-up ml-1 align-middle"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import {computed, defineComponent, nextTick, reactive, watch} from "@vue/composition-api";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture";
import definitions from "@/scripts/definitions";
import ReviewTagBar from "@/components/ReviewTagBar";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "componentMakerInfo";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ReviewTagBar, MemberPicture, Anchor},
  mixins: [mixin],
  props: {
    builderSeq: String,
    projectType: String,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      maker: {
        loaded: false,
        info: {
          blog: "",
          cheers: 0,
          email: "",
          facebook: "",
          followers: 0,
          followings: 0,
          homepage: "",
          insta: "",
          introduction: "",
          memberName: "",
          memberProfileUrl: "",
          news: 0,
          projects: 0,
          reviews: 0,
          supportedOpenFlag: "",
          supports: 0,
        },
      },
      intro: {
        expand: false,
        overflow: false,
      }
    });

    const computedIsAccordionShow = computed(() => {
      return state.maker.info.homepage || state.maker.info.email || state.maker.info.blog || state.maker.info.insta || state.maker.info.facebook;
    });

    const load = () => {
      if (!props.builderSeq) {
        return;
      }

      store.commit("setFollows");
      state.maker.loaded = false;

      http.get(`/api/channel/${props.builderSeq}`).then(({data}) => {
        state.maker.loaded = true;
        state.maker.info = data.body;

        nextTick(() => {
          const $introWrapper = document.getElementById(`${component.name}Intro`);
          const $introContent = $introWrapper?.querySelector("span");

          state.intro.overflow = $introContent?.getBoundingClientRect().height > $introWrapper?.getBoundingClientRect().height;
        });
      });
    };

    const copyEmailAddress = (mailAddress) => {
      lib.copyToClipboard(mailAddress.trim());
      store.commit("setSwingMessage", "이메일 주소를 클립보드에 복사하였습니다.");
    };

    const openMessageModal = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      } else if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: props.builderSeq,
            name: state.maker.info.memberName,
          }]
        }
      });
    };

    const toggleFollow = (seq) => {
      const args = {makerSeq: seq};

      if (!store.state.account.loggedIn) {
        redirectCallback.setAndLogin("toggleMemberFollow", args);
        return;
      }

      redirectCallback.run("toggleMemberFollow", args);
      setFollowerCount();
    };

    const toggleIntro = () => {
      state.intro.expand = !state.intro.expand;
    };

    const setFollowerCount = () => {
      if (store.state.follows.memberSequences.find(m => m.makerSeq === props.builderSeq)) {
        state.maker.info.followers -= 1;
      } else {
        state.maker.info.followers += 1;
      }
    };

    const getLink = (link) => {
      return link.startsWith("https://") ? link : "http://" + link.replace("http://", "");
    };

    watch(() => props.builderSeq, () => {
      load();
    });

    return {component, state, computedIsAccordionShow, copyEmailAddress, openMessageModal, toggleFollow, toggleIntro, getLink};
  }

});
</script>
<style lang="scss" scoped>

.profile {
  border: $px1 solid #f2f2f2;
  border-radius: $px24;
  position: relative;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #fff;

  .panel {
    padding: $px20;
    display: flex;
    flex-direction: column;
    gap: $px16;

    .top {
      display: flex;
      align-items: center;
      gap: $px12;

      > a {
        flex-shrink: 0;
        font-size: 0;
      }

      .right {
        > .badge {
          display: block;
          margin-top: $px2;
          width: fit-content;
        }
      }
    }

    .intro {
      .content {
        display: -webkit-box;
        max-height: $px200;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 9;
        -webkit-box-orient: vertical;
        line-height: 1.2;

        > span {
          white-space: break-spaces;
          font-size: $px12;
        }

        &.expand {
          display: block;
          overflow: initial;
          text-overflow: initial;
          max-height: initial;
        }
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: $px32;

      .stat {
        white-space: nowrap;
      }
    }

    .actions {
      display: flex;
      gap: $px8;

      .btn {
        border-radius: $px12;
        line-height: 1;
        padding: $px12;
        width: calc((100% - $px8) / 2);

        &.message {
          &:hover {
            color: $colorPoint;
            background-color: $colorBackground;
          }
        }

        &.edit {
          width: 100%;
        }
      }
    }
  }

  .accordion {
    .child {
      > div {
        padding: 0 $px20 $px16;

        .review-tag-bar::v-deep {
          margin-bottom: $px16;

          .title {
            margin-top: 0;
          }
        }

        .contacts {
          border-top: $px1 solid #f2f2f2;
          padding-top: $px16;

          > a {
            display: flex;
            align-items: start;
            gap: $px8;

            > .img {
              width: $px24;
              height: $px24;
              background-color: #f2f2f2;
              border-radius: 50%;
              background-size: $px16;
              background-position: center;
              flex-shrink: 0;
            }

            .address {
              padding-top: $px2;
            }

            &:not(:last-child) {
              margin-bottom: $px8;
            }
          }
        }

        .sns {
          border-top: $px1 solid #f2f2f2;
          margin-top: $px16;
          padding-top: $px16;
          display: flex;
          gap: $px8;
          align-items: center;

          .actions {
            font-size: 0;

            > a {
              width: $px24;
              height: $px24;

              &:not(:last-child) {
                margin-right: $px4;
              }
            }
          }
        }
      }
    }

    .parent {
      text-align: center;
      border-top: $px1 solid #f2f2f2;

      > div {
        padding: $px16;

        > span {
          &:before {
            content: "접기";
            line-height: 1;
          }
        }

        &.collapsed {
          > span {
            &:before {
              content: "더보기";
            }
          }

          > i {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.skeleton {
    .panel {
      .top {
        a > .img, .right a, .right span {
          @include skeleton;
        }
      }

      .intro, .status .stat span, .status .stat b, .actions .btn {
        @include skeleton;
      }
    }

    .accordion {
      display: none;
    }
  }
}
</style>